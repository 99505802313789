import parseUrl from 'url-parse';

const getCurrentQueryStringParameter = (
  param: string,
): string | undefined => {
  const parsedUrl = parseUrl(window.location.href, true);
  return parsedUrl.query[param];
};

const logout = () => {
  localStorage.clear();
  const currentUrl = window.location.origin + window.location.pathname;

  const redirectionUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/logout?redirectUrl=${currentUrl}`;
  /**
   * @TODO Implement logout?
   */
  window.location.href = redirectionUrl;
};

export default {
  getCurrentQueryStringParameter,
  logout,
};
