import React from 'react';

type EarthIconProps = {
  className?: string;
};

const EarthIcon = ({ className }: EarthIconProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Vector"
      d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476615 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9968 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0ZM19.1959 10.0833H16.0078C15.7999 7.69532 15.0845 5.37921 13.9095 3.28992C15.3299 3.82708 16.5747 4.74532 17.5073 5.94377C18.4399 7.14222 19.0242 8.57452 19.1959 10.0833ZM8.87792 12.8333H13.1221C12.7611 14.7535 12.0412 16.5884 11 18.2417C9.95865 16.5885 9.23869 14.7536 8.87792 12.8333ZM8.75692 10.0833C9.01364 7.8318 9.78081 5.66854 11 3.75833C12.2193 5.66847 12.9865 7.83176 13.2431 10.0833H8.75692ZM8.0905 3.28992C6.91549 5.37921 6.20012 7.69532 5.99225 10.0833H2.80409C2.97582 8.57452 3.56007 7.14222 4.49267 5.94377C5.42527 4.74532 6.67012 3.82708 8.0905 3.28992ZM2.96267 12.8333H6.08759C6.39603 14.8968 7.07455 16.8877 8.0905 18.7101C6.8183 18.2279 5.6847 17.4394 4.79022 16.4142C3.89574 15.3891 3.268 14.1591 2.96267 12.8333ZM13.9095 18.7101C14.9259 16.8879 15.6044 14.8969 15.9124 12.8333H19.0373C18.732 14.1591 18.1043 15.3891 17.2098 16.4142C16.3153 17.4394 15.1817 18.2279 13.9095 18.7101Z"
      fill="#176FFF"
    />
  </svg>
);

export default EarthIcon;

EarthIcon.defaultProps = {
  className: '',
};
