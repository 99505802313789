import { UserProfile } from '../../store/profile/types';

export const ROLES = {
  content_creator: 'CONTENT_CREATOR',
  student: 'STUDENT',
  salesAgent: 'SALES_AGENT',
  operation_manager: 'OPERATION_MANAGER',
  assistant_operation_manager: 'ASSISTANT_OPERATION_MANAGER',
  instructor: 'INSTRUCTOR',
  central_operation_manager: 'COM',
  recruiter: 'RECRUITER',
  staff: 'STAFF',
};

export const isInstructor = (roles: string[]) => roles.includes(ROLES.instructor);

export const isStudent = (roles: string[]) => roles.includes(ROLES.student);

export const hasPermission = (roles: string[]) => roles.includes(ROLES.instructor);

export const isOperationManager = (roles: string[]) => roles.includes(ROLES.operation_manager);

export const isCentralOperationManager = (roles: string[]) => roles.includes(ROLES.central_operation_manager);

export const isSalesAgent = (roles: string[]) => roles.includes(ROLES.salesAgent);

export const isAssistantOperationManager = (roles: string[]) => roles.includes(ROLES.assistant_operation_manager);

export const isManager = (roles: string[]) => isAssistantOperationManager(roles)
    || isOperationManager(roles)
    || isCentralOperationManager(roles);

export const isStaff = (roles: string[]) => roles.includes(ROLES.staff)
    || isInstructor(roles)
    || isOperationManager(roles)
    || isAssistantOperationManager(roles)
    || isCentralOperationManager(roles);

export const isOnlyInstructor = (roles: string[]) => !isSalesAgent(roles) && isInstructor(roles) && !isManager(roles);

export const isOnlyAssistantOperationManager = (roles: string[]) => isAssistantOperationManager(roles)
    && !isOperationManager(roles)
    && !isCentralOperationManager(roles);

export const isOnlyStudent = (roles: string[]) => isStudent(roles) && !isInstructor(roles) && !isManager(roles);

export const hasOnlyStudentrole = (roles: string[]) => !isSalesAgent(roles)
  && isStudent(roles)
  && !isInstructor(roles)
  && !isManager(roles)
  && !isStaff(roles);

export type SplitProfiles = {
    studentProfiles: UserProfile[];
    instructorProfiles: UserProfile[];
}

export const splitProfiles = (profiles: UserProfile[]): SplitProfiles => profiles.reduce((acc, item) => {
  if (isOnlyInstructor(item.profile.roles)) {
    acc.instructorProfiles.push(item);
  } else if (isOnlyStudent(item.profile.roles)) {
    acc.studentProfiles.push(item);
  }
  return acc;
}, { studentProfiles: [], instructorProfiles: [] } as SplitProfiles);
