import axios from 'axios';
import requestHeader from 'src/shared/utils/requestHeader';
import URL from 'src/shared/endPoints';
import axiosInstance from 'src/shared/utils/axios-config';
import { getQueryString } from '@gomycode/design-system';

const getProfileByToken = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axios.get(
    URL.baseApiUrl() + URL.account.profiles.getProfile + queryString,
    requestHeader({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '1',
    }),
  );
  return result.data;
};

const getProfiles = async (values: {email: string}) => {
  const { email } = values;

  const result = await axiosInstance.get(
    URL.account.profiles.getProfiles(email),
  );

  return result.data;
};

const switchDefaultProfile = async (values: {
  query: [];
  profileId: string;
}) => {
  const { query, profileId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.put(
    URL.account.profiles.switchDefaultProfile(profileId),
    null + queryString,
  );
  return result.data;
};

export default { getProfileByToken, getProfiles, switchDefaultProfile };
