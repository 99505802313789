/* eslint-disable import/prefer-default-export */
import { Button, Result } from 'antd';
import { isEmpty } from 'lodash';
import { ReactElement, useMemo } from 'react';
import urlHelpers from 'src/shared/utils/url-helpers';

const AuthErrorProvider = (props: { children: ReactElement; }) => {
  const errorCode = urlHelpers.getCurrentQueryStringParameter('error_code');
  const errorData = urlHelpers.getCurrentQueryStringParameter('data');

  const errorTitle = useMemo(() => {
    if (errorCode === 'CORRELATION_FAILED') {
      return 'Failed to authenticate you due to correlation issues.';
    } if (errorCode === 'USER_NOT_FOUND') {
      return 'The current account is not associated with Learn Platform.';
    }
    if (errorCode === 'USER_IS_ARCHIVED') {
      return 'The given account was deleted.';
    }

    return 'An unexpected authorization error occurred.';
  }, [errorCode]);

  const errorDescription = useMemo(() => {
    if (errorCode === 'CORRELATION_FAILED') {
      return "Something went wrong while authenticating you, most probably your browser or system configuration obstructed the authentication process, you can click on 'Retry'.";
    } if (errorCode === 'USER_NOT_FOUND') {
      return 'You may have your GOMYCODE account registered with other Platforms (e.g. Talent), but you need to be granted Learn access to be able to use this platform.';
    }
    if (errorCode === 'USER_IS_ARCHIVED') {
      return 'Your account was deleted or disabled, if you think this was a mistake contact support.';
    }

    return null;
  }, [errorCode]);

  const extra = useMemo(() => {
    const finalRedirectUrl = !isEmpty(errorData) ? errorData : window.location.origin + window.location.pathname;

    if (errorCode === 'CORRELATION_FAILED') {
      return (
        <Button onClick={() => {
          const redirectionUrl = process.env.REACT_APP_AUTH_METHOD === 'KEYCLOAK'
            ? `${process.env.REACT_APP_KEYCLOAK_URL}/external/gomycode?redirectUrl=${finalRedirectUrl}`
            : `${process.env.REACT_APP_AUTH_APP_URL}?logout=true`;
          // Comment this code to test locally without redirection
          window.location.href = redirectionUrl;
        }}
        >
          Retry
        </Button>
      );
    }

    if (errorCode === 'USER_IS_ARCHIVED' || errorCode === 'USER_NOT_FOUND') {
      return (
        <Button onClick={() => {
          urlHelpers.logout();
        }}
        >
          Logout
        </Button>
      );
    }

    return null;
  }, [errorCode, errorData]);
  return isEmpty(errorCode) ? (
    // eslint-disable-next-line react/destructuring-assignment
    props.children
  ) : (
    <Result
      status="403"
      title={errorTitle}
      subTitle={errorDescription}
      extra={extra}
    />
  );
};

export default AuthErrorProvider;
