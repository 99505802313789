export const sagaTypes = {
  GET: 'get',
  GET_BY_ID: 'get_by_id',
  DELETE: 'delete',
  POST: 'post',
  PUT: 'put',
  WITHOUT_API: 'without_api',
  GET_WITH_MULTIPLE_ID: 'get_with_multiple_id',
  DELETE_WITH_MULTIPLE_VALUES: 'delete_with_multiple_values',
  TEMPLATE_WITH_MULTIPLE_ID_AND_BODY: 'template_with_multiple_id_and_body',
  TEMPLATE_WITH_MULTIPLE_VALUES: 'template_with_multiple_values',
  GET_WITH_MULTIPLE_IDS: 'get_with_multiple_Ids',
};

export const generateActionWithBody = (type: string) => (body: {}) => ({
  type,
  body,
});

export const generateActionWithId = (type: string) => (id: string) => ({
  type,
  id,
});

export const generateActionWithMultipleId = (type: string) => (id1: string, id2: string) => ({
  type,
  id1,
  id2,
});

export const generateActionWithMultipleIdsAndBody = (type: string) => (id1: string, id2: string, id3: string, values: []) => ({
  type,
  id1,
  id2,
  id3,
  values,
});

export const generateActionWithMultipleIdAndBody = (type: string) => (id1: string, id2: string, body: string) => ({
  type,
  id1,
  id2,
  body,
});

export const generateEmptyAction = (type: string) => () => ({
  type,
});

export const generateActionWithBodyAndId = (type: string) => (id: string, body: {}) => ({
  type,
  id,
  body,
});

export const generateActionWithMultipleValues = (type: string) => (values: {}) => ({
  type,
  values,
});

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export const generateActionTypes = (root: string, prefix: string) => ({
  request: `${root}${prefix}_${REQUEST}`,
  success: `${root}${prefix}_${SUCCESS}`,
  failure: `${root}${prefix}_${FAILURE}`,
});
