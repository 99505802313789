import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

import profileSaga from './profile/sagas';
import { ProfileState } from './profile/types';
import { profileReducer } from './profile/reducer';

export interface ApplicationState {
  profile: ProfileState;
}

export const createRootReducer = () => combineReducers({
  profile: profileReducer,
});

export function* rootSaga() {
  yield all([fork(profileSaga)]);
}
