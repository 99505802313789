import React from 'react';

type MailIconProps = {
  className?: string;
};

const MailIcon = ({ className }: MailIconProps) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Vector"
      d="M18.5 0H5.5C4.0418 0.00158817 2.64377 0.581561 1.61267 1.61267C0.581561 2.64377 0.00158817 4.0418 0 5.5L0 16.5C0.00158817 17.9582 0.581561 19.3562 1.61267 20.3873C2.64377 21.4184 4.0418 21.9984 5.5 22H18.5C19.9582 21.9984 21.3562 21.4184 22.3873 20.3873C23.4184 19.3562 23.9984 17.9582 24 16.5V5.5C23.9984 4.0418 23.4184 2.64377 22.3873 1.61267C21.3562 0.581561 19.9582 0.00158817 18.5 0ZM18.5 3C19.1065 3.00202 19.6911 3.22655 20.143 3.631L13.643 10.131C13.2015 10.5527 12.6145 10.788 12.004 10.788C11.3935 10.788 10.8065 10.5527 10.365 10.131L3.865 3.631C4.31485 3.22837 4.8963 3.00397 5.5 3H18.5ZM18.5 19H5.5C4.83696 19 4.20107 18.7366 3.73223 18.2678C3.26339 17.7989 3 17.163 3 16.5V7.017L8.239 12.256C8.73276 12.75 9.319 13.1418 9.96424 13.4092C10.6095 13.6765 11.3011 13.8141 11.9995 13.8141C12.6979 13.8141 13.3895 13.6765 14.0348 13.4092C14.68 13.1418 15.2662 12.75 15.76 12.256L21 7.017V16.5C21 17.163 20.7366 17.7989 20.2678 18.2678C19.7989 18.7366 19.163 19 18.5 19Z"
      fill="#176FFF"
    />
  </svg>
);

export default MailIcon;

MailIcon.defaultProps = {
  className: '',
};
