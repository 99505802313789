import { Reducer } from 'redux';
import constants from './constants';
import { ProfileState } from './types';

export const initialState: ProfileState = {
  errors: undefined,
  loading: false,
  profile: {},
  location: {},
  getProfiles: [],
  getProfilesLoading: false,
  getProfilesErrors: [],
  switchDefaultProfileLoading: false,
  switchDefaultProfileErros: [],
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<ProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.getProfile.request:
      return { ...state, loading: true };

    case constants.getProfile.success: {
      return {
        ...state,
        loading: false,
        profile: action.data.model.profile,
        location: { ...action.data.model.location },
      };
    }
    case constants.getProfile.failure: {
      return { ...state, loading: false, errors: [] };
    }

    case constants.getProfiles.request:
      return { ...state, getProfilesLoading: true };

    case constants.getProfiles.success: {
      return {
        ...state,
        getProfilesLoading: false,
        getProfiles: action.data.model,
      };
    }
    case constants.getProfiles.failure: {
      return { ...state, getProfilesLoading: false, getProfilesErrors: [] };
    }

    case constants.switchDefaultProfile.request:
      return { ...state, switchDefaultProfileLoading: true };

    case constants.switchDefaultProfile.success: {
      localStorage.clear();
      if (action.request.target === 'student') {
        window.location.href = `${process.env.REACT_APP_STUDENT_APP_URL}?access_token=${encodeURIComponent(action.data.model.accessToken)}&expires=${encodeURIComponent(action.data.model.expires)}`;
      } else if (action.request.target === 'instructor') {
        window.location.href = `${process.env.REACT_APP_INSTRUCTOR_URL}?access_token=${encodeURIComponent(action.data.model.accessToken)}&expires=${encodeURIComponent(action.data.model.expires)}`;
      }
      return {
        ...state,
        switchDefaultProfileLoading: false,
      };
    }
    case constants.switchDefaultProfile.failure: {
      return {
        ...state,
        switchDefaultProfileLoading: false,
        switchDefaultProfileErros: [],
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as profileReducer };
