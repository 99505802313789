import React, { useEffect, useState } from 'react';
import InstructorTableIcon from 'src/shared/icons/InstructorTableIcon';
import GraduationCapIcon from 'src/shared/icons/GraduationCapIcon';
import { useDispatch, useSelector } from 'react-redux';
import profileActions from 'src/store/profile/actions';
import { Profile, ProfileState } from 'store/profile/types';
import HelpModal from 'src/shared/components/HelpModal';
import { splitProfiles } from 'src/shared/utils/roles';
import urlHelpers from 'src/shared/utils/url-helpers';
import styles from './Portal.module.css';

const handleTokens = () => {
  let expires = '';
  let accessToken = '';

  expires = urlHelpers.getCurrentQueryStringParameter('expires') || '';
  accessToken = urlHelpers.getCurrentQueryStringParameter('access_token') || '';

  if (accessToken && expires) {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('expires', expires);
  } else {
    accessToken = localStorage.getItem('token') || '';
    expires = localStorage.getItem('expires') || '';
  }

  if (!accessToken || !expires) {
    const finalRedirectUrl = window.location.origin + window.location.pathname;
    window.location.href = `${process.env.REACT_APP_KEYCLOAK_URL}/external/gomycode?redirectUrl=${finalRedirectUrl}`;
  }

  return { accessToken, expires };
};
const App = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { accessToken, expires } = handleTokens();

  const profile = useSelector(
    (state: { profile: { profile: Profile } }) => state.profile.profile,
  );

  const getProfiles = useSelector(
    (state: {profile: ProfileState }) => state.profile.getProfiles,
  );

  const [studentId, setStudentId] = useState('');
  const [instructorId, setInstructorId] = useState('');

  const { studentProfiles, instructorProfiles } = splitProfiles(getProfiles);
  useEffect(() => {
    if (getProfiles.length) {
      if (studentProfiles.length === 0) {
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_INSTRUCTOR_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
      } else if (instructorProfiles.length === 0) {
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_STUDENT_APP_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
      }
    }
  }, [getProfiles]);
  const handleProfiles = () => {
    const mostRecentInstructor = instructorProfiles.reduce((latest, profileDate) => (new Date(profileDate.profile.lastLoginDate) > new Date(latest.profile.lastLoginDate) ? profileDate : latest));
    const mostRecentStudent = studentProfiles.reduce((latest, profileDate) => (new Date(profileDate.profile.lastLoginDate) > new Date(latest.profile.lastLoginDate) ? profileDate : latest));
    setInstructorId(mostRecentInstructor?.profile?.id);
    setStudentId(mostRecentStudent?.profile?.id);
  };

  useEffect(() => {
    if (getProfiles.length > 0) {
      handleProfiles();
    }
  }, [getProfiles]);

  const switchDefaultInstructor = () => {
    if (profile?.id === instructorId && accessToken && expires) {
      window.location.href = `${process.env.REACT_APP_INSTRUCTOR_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
    } else {
      dispatch(
        profileActions.switchDefaultProfile({
          profileId: instructorId,
          target: 'instructor',
        }),
      );
    }
  };

  const switchDefaultStudent = () => {
    if (profile?.id === studentId && accessToken && expires) {
      window.location.href = `${process.env.REACT_APP_STUDENT_APP_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
    } else {
      dispatch(
        profileActions.switchDefaultProfile({
          profileId: studentId,
          target: 'student',
        }),
      );
    }
  };

  return (
    <div className={styles.portalBody}>
      <header className={`${styles.portalBody} ${styles.headerContainer}`}>
        <img src="/assets/images/gmcLogo.png" alt="gmc" />
        <div className={`${styles.headerContainer} ${styles.helpContainer}`} onClick={() => setIsModalOpen(true)}>
          <img src="/assets/images/handWave.png" alt="hand-wave" />
          <p className={styles.weAreHere}>We are here to help</p>
        </div>
      </header>
      <h1 className={styles.pageTitle}>
        Select which role you want to log in with
      </h1>
      <div className={styles.description}>
        <p className={styles.pageDescription}>
          Select the role you want to connect with. You can change this role after
          entering the platform by clicking on the drop-down list in the
          navigation bar.
        </p>
      </div>
      <div className={styles.cardsWrapper}>
        <div className={styles.cardStyles} onClick={switchDefaultInstructor}>
          <InstructorTableIcon className={styles.tableIcon} />
          <p className={styles.instructor}>Instructor</p>
        </div>
        <div className={styles.cardStyles} onClick={switchDefaultStudent}>
          <GraduationCapIcon className={styles.studentIcon} />
          <p className={styles.student}>Student</p>
        </div>
      </div>
      <HelpModal open={isModalOpen} onCancel={() => setIsModalOpen(false)} />
    </div>
  );
};

export default App;
