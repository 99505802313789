import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import profileActions from 'src/store/profile/actions';
import { Profile, ProfileState } from 'store/profile/types';
import {
  isCentralOperationManager, isOnlyAssistantOperationManager, isOnlyInstructor, isOnlyStudent, isSalesAgent,
} from 'src/shared/utils/roles';
import urlHelpers from 'src/shared/utils/url-helpers';
import Loader from 'src/shared/components/Loader';

interface IAuthProviderProps {
    children: ReactElement;
}

const handleTokens = () => {
  let expires = '';
  let accessToken = '';

  expires = urlHelpers.getCurrentQueryStringParameter('expires') || '';
  accessToken = urlHelpers.getCurrentQueryStringParameter('access_token') || '';

  if (accessToken && expires) {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('expires', expires);
  } else {
    accessToken = localStorage.getItem('token') || '';
    expires = localStorage.getItem('expires') || '';
  }

  if (!accessToken || !expires) {
    const finalRedirectUrl = window.location.origin + window.location.pathname;
    window.location.href = `${process.env.REACT_APP_KEYCLOAK_URL}/external/gomycode?redirectUrl=${finalRedirectUrl}`;
  }

  return { accessToken, expires };
};

const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [isAppReady, setIsAppReady] = useState(false);

  const { accessToken, expires } = handleTokens();

  const profile = useSelector(
    (state: { profile: { profile: Profile } }) => state.profile.profile,
  );

  const getProfiles = useSelector(
    (state: {profile: ProfileState }) => state.profile.getProfiles,
  );

  useEffect(() => {
    dispatch(profileActions.getProfile({ query: [] }));
  }, []);

  const userEmail = profile?.email;
  useEffect(() => {
    if (userEmail) {
      dispatch(profileActions.getProfiles({
        email: userEmail,
        query: [],
      }));
    }
  }, [userEmail]);

  useEffect(() => {
    if (getProfiles.length) {
      if (getProfiles.length === 1 && accessToken && expires) {
        const { roles } = getProfiles[0]?.profile;
        let url = null;

        if (isOnlyStudent(roles)) {
          url = `${process.env.REACT_APP_STUDENT_APP_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
        }

        if (isOnlyInstructor(roles)) {
          url = `${process.env.REACT_APP_INSTRUCTOR_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
        }

        if (isSalesAgent(roles)) {
          url = `${process.env.REACT_APP_SALES_APP_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
        }

        if (isOnlyAssistantOperationManager(roles)) {
          url = `${process.env.REACT_APP_OPERATION_APP_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
        }

        if (isCentralOperationManager(roles)) {
          url = `${process.env.REACT_APP_LEARN_APP_URL}?access_token=${encodeURIComponent(accessToken)}&expires=${encodeURIComponent(expires)}`;
        }

        if (url) {
          localStorage.clear();
          window.location.href = url;
        }
      } else {
        setIsAppReady(true);
      }
    }
  }, [getProfiles]);

  return (
    isAppReady ? children : <Loader />
  );
};

export default AuthProvider;
