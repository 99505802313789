import { generateActionWithMultipleValues } from 'src/shared/utils/generic-saga';
import constants from './constants';

const getProfile = generateActionWithMultipleValues(
  constants.getProfile.request,
);

const getProfiles = generateActionWithMultipleValues(
  constants.getProfiles.request,
);

const switchDefaultProfile = generateActionWithMultipleValues(
  constants.switchDefaultProfile.request,
);

export default { getProfile, getProfiles, switchDefaultProfile };
