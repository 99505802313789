import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import profileApi from 'src/shared/services/profile';
import constants from './constants';

export function* getProfile(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof profileApi.getProfileByToken> = yield call(
      profileApi.getProfileByToken,
      action.values,
    );
    yield put({
      type: constants.getProfile.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getProfile.failure,
      e,
    });
  }
}

export function* getProfileWatcher() {
  yield takeEvery(constants.getProfile.request, getProfile);
}

export function* getProfiles(action: {
  type: string;
  values: {
    email: string;
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof profileApi.getProfiles> = yield call(
      profileApi.getProfiles,
      action.values,
    );
    yield put({
      type: constants.getProfiles.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getProfiles.failure,
      e,
    });
  }
}

export function* getProfilesWatcher() {
  yield takeEvery(constants.getProfiles.request, getProfiles);
}

export function* switchDefaultProfile(action: {
  type: string;
  values: {
    query: [];
    profileId: string;
    target: string;
  };
}) {
  try {
    const result : ReturnType<typeof profileApi.switchDefaultProfile> = yield call(
      profileApi.switchDefaultProfile,
      action.values,
    );
    yield put({
      type: constants.switchDefaultProfile.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.switchDefaultProfile.failure,
      e,
    });
  }
}

export function* switchDefaultProfileWatcher() {
  yield takeEvery(
    constants.switchDefaultProfile.request,
    switchDefaultProfile,
  );
}

export default function* profileSaga() {
  yield all([getProfileWatcher(),
    getProfilesWatcher(),
    switchDefaultProfileWatcher(),
  ]);
}
