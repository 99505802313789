import { Modal } from 'antd';
import MailIcon from 'src/shared/icons/MailIcon';
import EarthIcon from 'src/shared/icons/EarthIcon';
import CloseIcon from 'src/shared/icons/CloseIcon';
import styles from './HelpModal.module.css';

interface IHelpModalProps {
  open: boolean;
  onCancel: () => void;
}

const HelpModal:React.FC<IHelpModalProps> = ({
  open,
  onCancel,
}: IHelpModalProps) => (
  <Modal
    open={open}
    closeIcon={<CloseIcon />}
    onCancel={onCancel}
    centered
    footer={false}
    width={936}
    className={styles.HelpModal}
  >
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <img src="/assets/images/handWave.png" alt="hand" />
        <p className={styles.headerTitle}>We are here to help</p>
      </div>
      <p className={styles.headerQuestion}>Do you have a question?</p>
      <div className={styles.cardsContainer}>
        <div className={styles.cardStyle}>
          <MailIcon />
          <p className={styles.getInTouch}>Get in touch directly with the support team.</p>
          <p className={styles.supportEmail}>support@gomycode.co</p>
        </div>
        <div className={styles.cardStyle}>
          <EarthIcon />
          <p className={styles.useSupport}>Use our support page to find answers to your questions.</p>
          <a className={styles.helpLink} href="https://help.gomycode.com" target="_blank" rel="noreferrer">https://help.gomycode.com</a>
        </div>
      </div>
    </div>
  </Modal>
);

export default HelpModal;
