import moment from 'moment';

const requestHeader = (options: {}) => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Accept-Language': 'en-US',
    'Access-Control-Allow-Origin': '*',
    'X-Timezone': `${moment().utcOffset()}`,
    Origin: window.location.origin,
    ...options,
  },
});

export default requestHeader;
