import React from 'react';

type CloseIconProps = {
  className?: string;
};

const CloseIcon = ({ className }: CloseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    className={className}
  >
    <path
      d="M12.0458 10.4543L10.5915 9L12.0458 7.54575C12.4853 7.10625 12.4853 6.3945 12.0458 5.955C11.826 5.73525 11.538 5.62575 11.25 5.62575C10.962 5.62575 10.674 5.73525 10.4543 5.955L9 7.40925L7.54575 5.955C7.326 5.73525 7.038 5.62575 6.75 5.62575C6.462 5.62575 6.174 5.73525 5.95425 5.955C5.51475 6.3945 5.51475 7.10625 5.95425 7.54575L7.4085 9L5.95425 10.4543C5.51475 10.8938 5.51475 11.6055 5.95425 12.045C6.39375 12.4845 7.1055 12.4845 7.545 12.045L8.99925 10.5908L10.4535 12.045C10.893 12.4845 11.6047 12.4845 12.0443 12.045C12.4838 11.6055 12.4838 10.8938 12.0443 10.4543H12.0458ZM18 9C18 4.03725 13.9628 0 9 0C4.03725 0 0 4.03725 0 9C0 13.9627 4.03725 18 9 18C13.9628 18 18 13.9627 18 9ZM15.75 9C15.75 12.7222 12.7222 15.75 9 15.75C5.27775 15.75 2.25 12.7222 2.25 9C2.25 5.27775 5.2785 2.25 9 2.25C12.7215 2.25 15.75 5.27775 15.75 9Z"
      fill="#828A92"
    />
  </svg>
);

export default CloseIcon;

CloseIcon.defaultProps = {
  className: '',
};
