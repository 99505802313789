const account = {
  profiles: {
    getProfile: 'account/profiles',
    getProfiles: (email: string) => `account/profiles/${email}`,
    switchDefaultProfile: (profileId: string) => `account/profiles/${profileId}/default`,
  },
};

const constants = {
  baseApiUrl: (): string => {
    if (process.env.REACT_APP_LEARN_ENDPOINT) return process.env.REACT_APP_LEARN_ENDPOINT;
    throw new Error('REACT_APP_LEARN_ENDPOINT is not defined ');
  },
  account,
};

export default constants;
